<template>
  <div class="row pt-1" v-if="berechtigungen.m_anmeldungen.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.website") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-2" :class="kachelStyle">
                  <router-link
                    :to="{
                      name: 'webseite-anmeldungen-liste',
                      params: { anzeige: 0 },
                    }"
                  >
                    <div class="tile bg-light-brown inverted pos-r">
                      <div
                        class="col-7 d-flex justify-content-center align-items-center h-100 flex-wrap"
                      >
                        <div
                          class="icon-container fa-container mr-3 h-100-px w-100-px"
                        >
                          <font-awesome-icon
                            icon="fa-duotone fa-users-rectangle"
                            transform="left-2 shrink-4"
                          />
                        </div>
                        <div class="mr-3">{{ $t("global.registrations") }}</div>
                      </div>
                      <div class="pos-a right-12 centered-y text-center fs-13">
                        <!-- <div class="information mb-1 p-1">
                          {{ $t("global.new") }}:
                          {{
                            webseiteDashboardinfo.new
                              ? webseiteDashboardinfo.new
                              : 0
                          }}
                        </div> -->
                        <div class="information mb-1 p-1">
                          {{ $t("global.consultations") }}:
                          {{
                            webseiteDashboardinfo.consultations
                              ? webseiteDashboardinfo.consultations
                              : 0
                          }}
                        </div>
                        <div class="information mb-1 p-1">
                          {{ $t("global.infoevents") }}:
                          {{
                            webseiteDashboardinfo.information_event
                              ? webseiteDashboardinfo.information_event
                              : 0
                          }}
                        </div>
                        <div class="information mb-1 p-1">
                          {{ $t("dashboard.events") }}:
                          {{
                            webseiteDashboardinfo.events
                              ? webseiteDashboardinfo.events
                              : 0
                          }}
                        </div>
                        <!-- <div class="information mb-1 p-1">
                          {{ $t("global.duplicates") }}:
                          {{
                            webseiteDashboardinfo.duplicates
                              ? webseiteDashboardinfo.duplicates
                              : 0
                          }}
                        </div> -->
                        <!-- <div class="information mb-1 p-1">
                          {{ $t("global.total") }}:
                          {{
                            webseiteDashboardinfo.total
                              ? webseiteDashboardinfo.total
                              : 0
                          }}
                        </div> -->
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WebsiteApi from "@/WebsiteApi";

export default {
  name: "StartseiteWebseite",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
  },
  data() {
    return {
      webseiteDashboardinfo: {
        new: null,
        consultations: null,
        information_event: null,
        duplicates: null,
        total: null,
      },
    };
  },
  created() {
    this.getwebseiteDashboardinfo();
  },
  mounted() {},
  computed: {},
  methods: {
    getwebseiteDashboardinfo() {
      WebsiteApi.get("dashboardinfo/").then((response) => {
        this.webseiteDashboardinfo = response.data.content[0];
      });
    },
  },
};
</script>
